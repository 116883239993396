export const morphCirclePath =
  "M454 336C454 346.224 453.138 356.247 451.483 366C449.669 376.688 446.902 387.051 443.273 397C438.9 408.988 433.275 420.374 426.556 431C421.406 439.144 415.614 446.842 409.25 454.022C401.2 463.104 392.235 471.358 382.5 478.638C372.727 485.946 362.179 492.274 351 497.475C346.276 499.673 341.439 501.67 336.5 503.454C329.842 505.86 323 507.88 316 509.488C303.142 512.44 289.753 514 276 514C268.551 514 261.209 513.542 254 512.654C245.815 511.645 237.802 510.08 230 507.999C218.683 504.98 207.81 500.874 197.5 495.8C190.204 492.209 183.19 488.133 176.5 483.615C166.604 476.931 157.416 469.279 149.072 460.793C142.426 454.034 136.316 446.747 130.81 439C125.315 431.268 120.423 423.079 116.2 414.5C113.41 408.83 110.912 402.99 108.727 397C106.647 391.297 104.85 385.458 103.354 379.5C99.8569 365.579 98 351.006 98 336C98 322.247 99.5598 308.858 102.512 296C104.607 286.879 107.402 278.026 110.838 269.5C113.116 263.847 115.676 258.339 118.5 252.991C122.17 246.042 126.286 239.366 130.81 233C137.93 222.983 146.06 213.734 155.052 205.402C162.909 198.122 171.425 191.542 180.5 185.761C188.277 180.807 196.464 176.441 205 172.724C215.969 167.947 227.514 164.244 239.5 161.746C251.281 159.291 263.49 158 276 158C289.575 158 302.795 159.52 315.5 162.398C327.323 165.077 338.7 168.933 349.5 173.836C362.201 179.601 374.104 186.815 385 195.266C390.971 199.897 396.638 204.9 401.969 210.239C407.379 215.658 412.441 221.424 417.12 227.5C423.271 235.488 428.758 244.012 433.5 252.991C437.804 261.14 441.494 269.664 444.508 278.5C448.225 289.396 450.915 300.768 452.462 312.5C453.477 320.189 454 328.034 454 336Z";
export const morphBrainPath =
  "M470 337C483 338 493.582 369.759 491.926 379.512C490.112 390.2 487.346 400.563 483.717 410.512C479.344 422.5 473.719 433.887 467 444.512C461.85 452.657 453.864 452.8 447.5 459.979C439.45 469.062 423.156 476 411 476C389 476 377 480 361.5 473C349 467.355 349 464.521 342.5 460C332 453.521 319.5 440.393 312.5 442C299.642 444.952 291.253 435.5 277.5 435.5C270.051 435.5 257.709 445.401 250.5 444.512C242.315 443.503 247.302 444.081 239.5 442C228.183 438.981 223.5 453.5 216.5 458.5C208.8 464 206 470.5 187.5 475.5C177 476 164 478.5 149 476C139 473 120.506 471.247 115 463.5C109.505 455.768 93.7223 449.079 89.5 440.5C86.7095 434.83 87.6851 429.49 85.5 423.5C83.4198 417.797 76.1497 412.958 74.6532 407C71.1567 393.079 61.5 342 93 339.5C76 333.5 75.0476 310.358 78 297.5C80.0943 288.379 89.5639 281.526 93 273C95.2781 267.347 95.7033 259.839 98.5276 254.491C102.197 247.542 106.313 240.866 110.838 234.5C117.957 224.483 139.56 213.51 148.552 205.178C156.409 197.898 164.925 191.318 174 185.537C181.777 180.583 189.964 176.217 198.5 172.5C217.5 161.746 227.514 164.244 239.5 161.746C260 157.474 264.99 178 277.5 178C291.075 178 299 163.633 315.5 162.398C327.5 161.5 341.2 163.097 352 168C364.701 173.766 388.104 186.049 399 194.5C404.971 199.131 408.169 206.161 413.5 211.5C418.91 216.919 435 220 444.5 231C456 243.5 453.258 250.521 458 259.5C462.304 267.649 470.5 274.5 477.5 284.5C484.5 294.5 484.415 306.63 485.963 318.362C486.977 326.052 479.5 335.5 470 337Z";
export const intro = {
  id1: "#intro-1",
  id2: "#intro-2",
  id2b: "#intro-2-b",
  id3: "#intro-3",
  id4: "#intro-4",
  id5: "#intro-5"
};
export const dir = {
  forward: "FORWARD",
  reverse: "REVERSE"
};
